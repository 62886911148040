html {
  font-size: 0.75rem;

  .ant-message {
    .ant-message-notice-content {
      @apply max-w-sm  bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;

      .ant-message-custom-content {
        @apply inline-flex items-center;

        .anticon {
          top: 0 !important;
        }
      }

      &-success {
        @apply text-green-400;
      }
      &-info {
        @apply text-blue-400;
      }
      &-error {
        @apply text-red-400;
      }
      &-warning {
        @apply text-yellow-400;
      }
    }
  }

  .ant-input {
    @apply block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md;

    &:focus {
      @apply ring-blue-500 border-blue-500;
    }
  }

  .ant-drawer {
    &-open {
      &-mask {
        @apply bg-black opacity-5 w-full h-full fixed z-10 top-0 left-0 right-0 bottom-0;
      }

      &-content {
        @apply fixed z-10 w-5/12 h-full  bg-white shadow-lg overflow-hidden overflow-y-scroll;
      }
    }

    &-header {
      @apply text-lg px-5 py-6 bg-gray-50 border-none font-normal;
    }

    &-title {
      @apply font-normal text-gray-700;
    }

    &-close {
      @apply outline-none top-6 right-5 text-gray-700 hover:bg-white p-1 leading-none rounded;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-body {
      @apply px-5 pb-5;
    }
  }

  .ant-input {
    @apply border w-full my-1 py-2 px-3 rounded-md text-base appearance-none;
  }

  .ant-breadcrumb-separator {
    @apply text-gray-200;
  }

  .ant-row.ant-form-item {
    @apply mb-0;
  }

  .ant-tag {
    @apply mx-1;
  }

  .ant-dropdown-menu-title-content {
    @apply px-10;
  }

  .ant-input-group .ant-input {
    @apply mt-0;
  }

  .ant-list.ant-list-split {
    @apply bg-white shadow sm:rounded-md;
  }
  
  .ant-list.ant-list-split .ant-list-items {
    @apply divide-y divide-gray-200;
  }

  .ant-list.ant-list-split .ant-list-item {
    @apply px-4 py-4 sm:px-6;
  }

  .ant-tag .anticon {
    vertical-align: 0.025rem;
  }

  .ant-card {
    @apply mb-5;
  }

  .ant-list-item-action a {
    @apply text-blue-500;
  }

  .ant-table,.ant-btn {
    font-size: 14px;
  }

  .ant-list {
    @apply p-5;
  }

  .ant-card-none {
    padding: 0;
    background: transparent;
    border: 0;
    box-shadow: none !important;

    .ant-card-header,
    .ant-card-footer,
    .ant-card-body {
      margin: 0;
      padding: 0;
    }
  }
}
