.is-open {
  transform: rotate(90deg);
}

.is-closed {
  transform: rotate(-90deg);
}

.slow-transition {
  transition: all 0.5s ease-in-out;
}

.blue-hover {
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: blue;
  }
}

.resizer {
  transition: all 1s ease-in-out;
  max-height: 1000px;
  overflow: hidden;

  &.shrink {
    max-height: 0px;
  }
}

label.requied-form-label:not(.requied-form-label-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ck .ck-content {
  min-height: 800px;
}